<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <form class="needs-validation" @submit.prevent="Form">
            <div class="card-body">
              <h4 class="card-title"></h4>

              <div class="row mt-2">
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <div class="row align-items-center">
                      <div class="col-md-9 col-sm-9">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="nameTh">การขาย</label>:
                          <b-form-input
                            v-model="modal"
                            type="search"
                            placeholder="ค้นหาการขาย"
                            class="form-control ms-2"
                            v-b-modal.modalSale
                          ></b-form-input>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-3 col-3">
                        <div class="mt-2 position-relative">
                          <b-button
                            class="btn"
                            variant="info"
                            v-b-modal.modalSale
                          >
                            <i class="uil-file-search-alt"></i
                          ></b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text-end">
                <button type="submit" class="btn btn-success">บันทึก</button>
                &nbsp;
              </div> -->
            </div>
          </form>
          <b-modal
            ref="modalSale"
            id="modalSale"
            title="รายการการขาย"
            hide-footer
            size="xl"
            centered
          >
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center">
                          สาขา:</label
                        >
                        <multiselect
                          v-model="filter.branchId"
                          :options="localData"
                          label="nameTh"
                          :show-labels="false"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="mb-3 col-sm-4 col-md-4">
                      <label class="d-inline align-items-center">
                        ชื่อ-นามสกุลลูกค้า:
                        <b-form-input
                          v-model="filter.customer"
                          type="search"
                          placeholder="ชื่อ-นามสกุล"
                          class="form-control"
                        ></b-form-input>
                      </label>
                    </div>
                    <div class="col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center"
                          >ปี (ค.ศ):</label
                        >
                        <multiselect
                          v-model="filter.year"
                          :options="optionYear"
                          label="id"
                          :show-labels="false"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center"
                          >เดือน :</label
                        >
                        <multiselect
                          v-model="filter.month"
                          :options="optionMonth"
                          label="name"
                          :show-labels="false"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center">
                          สถานะ:</label
                        >
                        <multiselect
                          id="occId"
                          v-model="filter.status"
                          :options="statusOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div
                      class="col-2 col-sm-2 col-md-2 text-end"
                      style="margin-left: auto; margin-right: 0"
                    >
                      <b-button
                        class="btn btn ms-2"
                        variant="info"
                        type="submit"
                        @click="handleSearch"
                      >
                        <i class="uil-search"></i>
                        ค้นหา
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <b-skeleton-wrapper :loading="loading">
                <template #loading>
                  <b-skeleton-table
                    :rows="5"
                    :columns="6"
                    :table-props="{ bordered: false, striped: true }"
                    animation="throb"
                  ></b-skeleton-table>
                </template>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12">
                    <div class="row align-items-center">
                      <div class="col-sm-5 col-md-3">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-block align-items-center">
                            แสดงผล
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                              @input="handlePageChange"
                            ></b-form-select
                            >&nbsp; รายการ
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6"></div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive mb-0">
                  <b-table
                    :items="rowData"
                    :fields="fields"
                    responsive="true"
                    :per-page="perPage"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    ref="selectableTable"
                    selectable
                    :select-mode="selectMode"
                    @row-selected="select"
                  >
                    <template #cell(saleCondition)="rowData">
                      <span>
                        {{ checksaleCondition(rowData.item.saleCondition) }}
                      </span>
                    </template>
                    <template
                      #cell(index)="rowData"
                      v-if="this.currentPage > 1"
                    >
                      {{
                        rowData.index + 1 + (currentPage * perPage - perPage)
                      }}
                    </template>
                    <template #cell(index)="rowData" v-else>
                      {{ rowData.index + 1 }}
                    </template>
                    <template #cell(saleDate)="rowData">
                      <changeDate :date="rowData.item.saleDate"></changeDate>
                    </template>
                    <template #cell(status)="rowData">
                      <span
                        class="badge bg-warning font-size-12 ms-2"
                        v-if="rowData.item.status === 'DFT'"
                      >
                        ร่าง
                      </span>

                      <span
                        class="badge bg-success font-size-12 ms-2"
                        v-if="rowData.item.status === 'APV'"
                      >
                        ยืนยันแล้ว
                      </span>
                      <span
                        class="badge bg-danger font-size-12 ms-2"
                        v-if="rowData.item.status === 'L'"
                      >
                        Lost
                      </span>
                    </template>
                  </b-table>
                </div>

                <div class="row">
                  <span
                    v-if="this.totalRecord === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                  <div class="col" v-if="this.totalRecord > 0">
                    <div class="col">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>

                    <div class="col">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          float-end
                        "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecord"
                            :per-page="perPage"
                            @change="handleChangePage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                </div>
              </b-skeleton-wrapper>
            </div>

            <div class="row">
              <div class="col-md">
                <button class="btn btn-success float-end">ตกลง</button>
              </div>
            </div>
          </b-modal>
          <hr />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ChangeDate,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.auditSale,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "เพิ่มรูปส่งมอบรถ",
      items: [
        {
          text: "ตรวจมาตรฐาน",
          active: true,
        },
        {
          text: appConfig.auditSale,
          href: "/branch-audit-sale",
        },
        {
          text: "เพิ่มรูปส่งมอบรถ",
          active: true,
        },
      ],
      filter: {
        branchId: "",
        customer: "",
        status: "",
        year: "",
        month: "",
      },
      modal: "",
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      saleId: "",
      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "saleCode",
          sortable: true,
          label: "เลขที่การส่งมอบ/ขายรถ",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "เลขที่ใบจอง",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "productNameTh",
          sortable: true,
          label: "สินค้า",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคาสุทธิ",
        },
        {
          key: "saleCondition",
          sortable: true,
          label: "ประเภทการซื้อ",
        },
        {
          key: "saleDate",
          sortable: true,
          label: "วันที่ส่งมอบ/ขายรถ",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      statusOption: [
        { id: "DFT", nameTh: "ร่าง" },
        { id: "WIP", nameTh: "รอดำเนินการ" },
        { id: "APV", nameTh: "ยืนยัน" },
      ],
      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      optionYear: [
        { id: "2022" },
        { id: "2021" },
        { id: "2020" },
        { id: "2019" },
      ],
      optionMonth: [
        { id: "01", name: "มกราคม" },
        { id: "02", name: "กุมภาพันธ์" },
        { id: "03", name: "มีนาคม " },
        { id: "04", name: "เมษายน" },
        { id: "05", name: "พฤษภาคม" },
        { id: "06", name: "มิถุนายน" },
        { id: "07", name: "กรกฎาคม" },
        { id: "08", name: "สิงหาคม" },
        { id: "09", name: "กันยายน" },
        { id: "10", name: "ตุลาคม" },
        { id: "11", name: "พฤศจิกายน" },
        { id: "12", name: "ธันวาคม" },
      ],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      typeOption: [{ nameTh: "หลังการขาย", id: "SALE" }],
      form: {
        manageBranch: "",
        subjectCode: "",
        type: "",
        nameTh: "",
        nameEn: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    form: {
      nameTh: {
        required,
      },
      type: {
        required,
      },
    },
  },
  mounted() {},
  computed: {
    fullAddress: function () {
      //

      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    this.getLocalData();
  },
  methods: {
    checksaleCondition(data) {
      let type;
      if (data == "C") {
        type = "เงินสด";
      } else if (data == "I") {
        type = "ผ่อนชำระ";
      } else if (data == "F") {
        type = "ไฟแนนซ์";
      }
      return type;
    },
    // select(data) {
    //   const dataArr = data[0];
    //   this.saleId = dataArr.saleId;
    //   console.log(this.saleId);
    // },
    select(data) {
      const dataArr = data[0];
      Swal.fire({
        title: `ต้องการสร้างใบตรวจสอบรูปส่งมอบ \n ${dataArr.saleCode} \n ใช่หรอไม่?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.submitSale(dataArr);
        }
      });
    },
    submitSale(data) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/audit-sale/store", {
          saleId: data.saleId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "edit-auditSale",
            params: {
              auditId: btoa(response.data.auditId),
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    handleSearch() {
      if (this.filter.branchId === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.filter.branchId.branchId];
      }
      this.getData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;
      this.getData();
      // console.log("สาขาาาาาา", this.localDataBranchId);
    },
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postData();
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/audit-sale/sale", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchId,
            year: this.filter.year.id,
            customer: this.filter.customer,
            month: this.filter.month.id,
            status: this.filter.status.id,
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    postData: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/d-audit-subject/store", {
          subjectCode: this.form.subjectCode,
          nameTh: this.form.nameTh,
          nameEn: this.form.nameEn,
          manageBranch: this.form.manageBranch,
          type: this.form.type.id,
        })
        .then((response) => {
          // this.getUser();
          //con
          this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({
            name: "edit-suditSubject",
            params: {
              subjectId: btoa(response.data.subjectId),
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
</style>
